import { Charity } from '~/entities/charity'
import { Comment } from '~/entities/comment'
import { FuneralCompany } from '~/entities/funeral-company'
import { FuneralInformation } from '~/entities/funeral-information'
import { FuneralLocation } from '~/entities/funeral-location'
import { Gesture } from '~/entities/gesture'
import { Location } from '~/entities/location'
import { Media } from '~/entities/media'
import { Monument } from '~/entities/monument'
import { Page } from '~/entities/page'
import { Register } from '~/entities/register'
import { Staff } from '~/entities/staff'
import { Visitor } from '~/entities/visitor'

export class EntityReviver<T extends { toPOJO(): object }> {
  private entityMap: Map<string, new (copyFrom: T) => T> = new Map()
    .set('location', Location)
    .set('register', Register)
    .set('gesture', Gesture)
    .set('comment', Comment)
    .set('visitor', Visitor)
    .set('monument', Monument)
    .set('funeralcompany', FuneralCompany)
    .set('media', Media)
    .set('charity', Charity)
    .set('staff', Staff)
    .set('funerallocation', FuneralLocation)
    .set('funeralinformation', FuneralInformation)
    .set('page', Page)

  public revive(entity: string, data: object): T {
    const constructor = this.entityMap.get(entity.toLowerCase())
    if (!constructor) {
      throw new Error(`No constructor found for ${entity}`)
    }
    return new constructor(data as unknown as T)
  }
}
