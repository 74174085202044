import { default as indexQFn8dhKMOpMeta } from "/app/pages/memori/[municipality]/[city]/[deceasedName]/index.vue?macro=true";
import { default as indexo7ypW7aQJDMeta } from "/app/pages/memori/[municipality]/[city]/index.vue?macro=true";
import { default as index8IJSpu9l8FMeta } from "/app/pages/memori/[municipality]/index.vue?macro=true";
import { default as indextrpqA6PhLAMeta } from "/app/pages/memori/index.vue?macro=true";
import { default as indexwSc7yCFgGtMeta } from "/app/pages/memori/information/[slug]/index.vue?macro=true";
import { default as index_46clientenDKWUDgflMeta } from "/app/pages/uvw/[register]/condoleances/index.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/app/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as index_46clientA7b5VFFxP2Meta } from "/app/pages/uvw/[register]/contact/index.client.vue?macro=true";
import { default as index_46client7LuC9BKMgBMeta } from "/app/pages/uvw/[register]/iedereen-informeren/index.client.vue?macro=true";
import { default as index_46client5rk7GQwQlSMeta } from "/app/pages/uvw/[register]/index.client.vue?macro=true";
import { default as index_46client9xL7DBGAB9Meta } from "/app/pages/uvw/[register]/monument/index.client.vue?macro=true";
import { default as index_46clientQtphGY7punMeta } from "/app/pages/uvw/[register]/rouwbericht/index.client.vue?macro=true";
import { default as index_46clientyDOOUGFHSbMeta } from "/app/pages/uvw/[register]/uitvaartinformatie/[invitation]/index.client.vue?macro=true";
import { default as index_46clientbLm2xr9ZtMMeta } from "/app/pages/uvw/[register]/wensen/index.client.vue?macro=true";
import { default as index_46client857J6Ia8xkMeta } from "/app/pages/uvw/admin/[register]/index.client.vue?macro=true";
import { default as index_46clientdWrPs11O5BMeta } from "/app/pages/uvw/admin/[register]/uitvaartinformatie/index.client.vue?macro=true";
import { default as index_46client3q9jOxB4r6Meta } from "/app/pages/uvw/admin/aanmaken/index.client.vue?macro=true";
import { default as index_46clienth1PzmgdlE0Meta } from "/app/pages/uvw/admin/index.client.vue?macro=true";
import { default as index_46clientPGkpmjRiX5Meta } from "/app/pages/uvw/admin/locaties/index.client.vue?macro=true";
import { default as index_46clientzgnYGppxP2Meta } from "/app/pages/uvw/admin/medewerkers/index.client.vue?macro=true";
import { default as index_46clientD20NQZ15z1Meta } from "/app/pages/uvw/admin/pages/index.client.vue?macro=true";
import { default as index_46clientWuBfqjL1GlMeta } from "/app/pages/uvw/index.client.vue?macro=true";
import { default as index_46clienteNvSvriqmTMeta } from "/app/pages/uvw/steun-bieden/index.client.vue?macro=true";
export default [
  {
    name: "memori-municipality-city-deceasedName",
    path: "/memori/:municipality()/:city()/:deceasedName()",
    meta: indexQFn8dhKMOpMeta || {},
    component: () => import("/app/pages/memori/[municipality]/[city]/[deceasedName]/index.vue").then(m => m.default || m)
  },
  {
    name: "memori-municipality-city",
    path: "/memori/:municipality()/:city()",
    meta: indexo7ypW7aQJDMeta || {},
    component: () => import("/app/pages/memori/[municipality]/[city]/index.vue").then(m => m.default || m)
  },
  {
    name: "memori-municipality",
    path: "/memori/:municipality()",
    meta: index8IJSpu9l8FMeta || {},
    component: () => import("/app/pages/memori/[municipality]/index.vue").then(m => m.default || m)
  },
  {
    name: "memori",
    path: "/memori",
    component: () => import("/app/pages/memori/index.vue").then(m => m.default || m)
  },
  {
    name: "memori-information-slug",
    path: "/memori/information/:slug()",
    meta: indexwSc7yCFgGtMeta || {},
    component: () => import("/app/pages/memori/information/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "uvw-register-condoleances",
    path: "/uvw/:register()/condoleances",
    meta: index_46clientenDKWUDgflMeta || {},
    component: () => createClientPage(() => import("/app/pages/uvw/[register]/condoleances/index.client.vue").then(m => m.default || m))
  },
  {
    name: "uvw-register-contact",
    path: "/uvw/:register()/contact",
    meta: index_46clientA7b5VFFxP2Meta || {},
    component: () => createClientPage(() => import("/app/pages/uvw/[register]/contact/index.client.vue").then(m => m.default || m))
  },
  {
    name: "uvw-register-iedereen-informeren",
    path: "/uvw/:register()/iedereen-informeren",
    meta: index_46client7LuC9BKMgBMeta || {},
    component: () => createClientPage(() => import("/app/pages/uvw/[register]/iedereen-informeren/index.client.vue").then(m => m.default || m))
  },
  {
    name: "uvw-register",
    path: "/uvw/:register()",
    meta: index_46client5rk7GQwQlSMeta || {},
    component: () => createClientPage(() => import("/app/pages/uvw/[register]/index.client.vue").then(m => m.default || m))
  },
  {
    name: "uvw-register-monument",
    path: "/uvw/:register()/monument",
    meta: index_46client9xL7DBGAB9Meta || {},
    component: () => createClientPage(() => import("/app/pages/uvw/[register]/monument/index.client.vue").then(m => m.default || m))
  },
  {
    name: "uvw-register-rouwbericht",
    path: "/uvw/:register()/rouwbericht",
    meta: index_46clientQtphGY7punMeta || {},
    component: () => createClientPage(() => import("/app/pages/uvw/[register]/rouwbericht/index.client.vue").then(m => m.default || m))
  },
  {
    name: "uvw-register-uitvaartinformatie-invitation",
    path: "/uvw/:register()/uitvaartinformatie/:invitation()",
    meta: index_46clientyDOOUGFHSbMeta || {},
    component: () => createClientPage(() => import("/app/pages/uvw/[register]/uitvaartinformatie/[invitation]/index.client.vue").then(m => m.default || m))
  },
  {
    name: "uvw-register-wensen",
    path: "/uvw/:register()/wensen",
    meta: index_46clientbLm2xr9ZtMMeta || {},
    component: () => createClientPage(() => import("/app/pages/uvw/[register]/wensen/index.client.vue").then(m => m.default || m))
  },
  {
    name: "uvw-admin-register",
    path: "/uvw/admin/:register()",
    meta: index_46client857J6Ia8xkMeta || {},
    component: () => createClientPage(() => import("/app/pages/uvw/admin/[register]/index.client.vue").then(m => m.default || m))
  },
  {
    name: "uvw-admin-register-uitvaartinformatie",
    path: "/uvw/admin/:register()/uitvaartinformatie",
    meta: index_46clientdWrPs11O5BMeta || {},
    component: () => createClientPage(() => import("/app/pages/uvw/admin/[register]/uitvaartinformatie/index.client.vue").then(m => m.default || m))
  },
  {
    name: "uvw-admin-aanmaken",
    path: "/uvw/admin/aanmaken",
    meta: index_46client3q9jOxB4r6Meta || {},
    component: () => createClientPage(() => import("/app/pages/uvw/admin/aanmaken/index.client.vue").then(m => m.default || m))
  },
  {
    name: "uvw-admin",
    path: "/uvw/admin",
    meta: index_46clienth1PzmgdlE0Meta || {},
    component: () => createClientPage(() => import("/app/pages/uvw/admin/index.client.vue").then(m => m.default || m))
  },
  {
    name: "uvw-admin-locaties",
    path: "/uvw/admin/locaties",
    meta: index_46clientPGkpmjRiX5Meta || {},
    component: () => createClientPage(() => import("/app/pages/uvw/admin/locaties/index.client.vue").then(m => m.default || m))
  },
  {
    name: "uvw-admin-medewerkers",
    path: "/uvw/admin/medewerkers",
    meta: index_46clientzgnYGppxP2Meta || {},
    component: () => createClientPage(() => import("/app/pages/uvw/admin/medewerkers/index.client.vue").then(m => m.default || m))
  },
  {
    name: "uvw-admin-pages",
    path: "/uvw/admin/pages",
    meta: index_46clientD20NQZ15z1Meta || {},
    component: () => createClientPage(() => import("/app/pages/uvw/admin/pages/index.client.vue").then(m => m.default || m))
  },
  {
    name: "uvw",
    path: "/uvw",
    meta: index_46clientWuBfqjL1GlMeta || {},
    component: () => createClientPage(() => import("/app/pages/uvw/index.client.vue").then(m => m.default || m))
  },
  {
    name: "uvw-steun-bieden",
    path: "/uvw/steun-bieden",
    meta: index_46clienteNvSvriqmTMeta || {},
    component: () => createClientPage(() => import("/app/pages/uvw/steun-bieden/index.client.vue").then(m => m.default || m))
  }
]