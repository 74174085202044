import type { GetProperty } from '~/util/get/get-property'

export interface Pageinterface {
  id: number
  slug: string
  title: string
  content: string
  seo_description: string
  created_at: string
  updated_at: string
  featured_image_id: number
  featured_image_path: GetProperty<string>
}

export class Page implements Pageinterface {
  id: number
  slug: string
  title: string
  content: string
  seo_description: string
  created_at: string
  updated_at: string
  featured_image_id: number
  featured_image_path: GetProperty<string>

  constructor(copyFrom: Pageinterface) {
    this.id = copyFrom.id
    this.slug = copyFrom.slug
    this.title = copyFrom.title
    this.content = copyFrom.content
    this.seo_description = copyFrom.seo_description
    this.created_at = copyFrom.created_at
    this.updated_at = copyFrom.updated_at
    this.featured_image_id = copyFrom.featured_image_id
    this.featured_image_path = copyFrom.featured_image_path
  }

  getFeaturedImageUrl(): string {
    return this.featured_image_path || ''
  }

  toPOJO(): Pageinterface {
    return {
      id: this.id,
      slug: this.slug,
      title: this.title,
      content: this.content,
      seo_description: this.seo_description,
      created_at: this.created_at,
      updated_at: this.updated_at,
      featured_image_id: this.featured_image_id,
      featured_image_path: this.featured_image_path
    }
  }
}
