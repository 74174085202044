import type { FuneralLocationInterface } from '~/entities/funeral-location'
import type { GetProperty } from '~/util/get/get-property'

export interface LocationInterface {
  id: number
  admin_id: number
  name: string
  slug: string
  type: string
  latitude: number
  longitude: number
  iso2: string
  created_at: string
  updated_at: string
  children_ids: number[]
  background_path: string
  number_of_light_candles: GetProperty<number>
  number_of_messages: GetProperty<number>
  number_of_flowers: GetProperty<number>
  related_locations: GetProperty<LocationInterface[]>
  cemeteries_near_by: GetProperty<FuneralLocationInterface[]>
}

export class Location implements LocationInterface {
  id: number
  admin_id: number
  name: string
  slug: string
  type: string
  latitude: number
  longitude: number
  iso2: string
  created_at: string
  updated_at: string
  children_ids: number[] = []
  background_path: string
  number_of_light_candles: GetProperty<number>
  number_of_messages: GetProperty<number>
  number_of_flowers: GetProperty<number>
  related_locations: GetProperty<LocationInterface[]>
  cemeteries_near_by: GetProperty<FuneralLocationInterface[]>

  constructor(copyFrom: LocationInterface) {
    this.id = copyFrom.id
    this.admin_id = copyFrom.admin_id
    this.name = copyFrom.name
    this.slug = copyFrom.slug
    this.type = copyFrom.type
    this.latitude = copyFrom.latitude
    this.longitude = copyFrom.longitude
    this.iso2 = copyFrom.iso2
    this.created_at = copyFrom.created_at
    this.updated_at = copyFrom.updated_at
    this.children_ids = copyFrom.children_ids
    this.background_path = copyFrom.background_path
    this.number_of_light_candles = copyFrom.number_of_light_candles
    this.number_of_messages = copyFrom.number_of_messages
    this.number_of_flowers = copyFrom.number_of_flowers
    this.related_locations = copyFrom.related_locations
    this.cemeteries_near_by = copyFrom.cemeteries_near_by
  }

  toPOJO(): LocationInterface {
    return {
      id: this.id,
      admin_id: this.admin_id,
      name: this.name,
      slug: this.slug,
      type: this.type,
      latitude: this.latitude,
      longitude: this.longitude,
      iso2: this.iso2,
      created_at: this.created_at,
      updated_at: this.updated_at,
      children_ids: this.children_ids,
      background_path: this.background_path,
      number_of_light_candles: this.number_of_light_candles,
      number_of_messages: this.number_of_messages,
      number_of_flowers: this.number_of_flowers,
      related_locations: this.related_locations,
      cemeteries_near_by: this.cemeteries_near_by
    }
  }
}
